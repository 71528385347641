export function ArrowRightV1(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <rect
        width="36"
        height="36"
        fill="#fff"
        opacity="0.4"
        rx="18"
        transform="matrix(-1 0 0 1 36 0)"
      ></rect>
      <g clipPath="url(#clip0_112_2612)">
        <path
          stroke="#fff"
          strokeWidth="1.5"
          d="M15.578 11.828l5.819 5.818-5.819 5.819"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_112_2612">
          <rect
            width="20.571"
            height="20.571"
            fill="#fff"
            rx="10.286"
            transform="matrix(0 1 1 0 7.7 7.713)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowLeftV1(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
      <rect width="36" height="36" fill="#fff" opacity="0.4" rx="18"></rect>
      <g clipPath="url(#clip0_112_2596)">
        <path
          stroke="#fff"
          strokeWidth="1.5"
          d="M20.422 11.828l-5.819 5.818 5.819 5.819"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_112_2596">
          <rect
            width="20.571"
            height="20.571"
            x="28.301"
            y="7.713"
            fill="#fff"
            rx="10.286"
            transform="rotate(90 28.3 7.713)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowRightV2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      fill="none"
      viewBox="0 0 36 24"
      {...props}
    >
      <rect
        width="36"
        height="24"
        fill="#fff"
        opacity="0.2"
        rx="12"
        transform="matrix(-1 0 0 1 36 0)"
      ></rect>
      <path
        fill="#D9D9D9"
        d="M19.417 16.333L24 11.667l-4.583 4.666zM24 11.667L19.417 7 24 11.667zm0 0H13h11z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.417 16.333L24 11.667m0 0L19.417 7M24 11.667H13"
      ></path>
    </svg>
  );
}

export function ArrowLeftV2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      fill="none"
      viewBox="0 0 36 24"
      {...props}
    >
      <rect width="36" height="24" fill="#fff" opacity="0.2" rx="12"></rect>
      <path
        fill="#D9D9D9"
        d="M16.583 16.333L12 11.667l4.583 4.666zM12 11.667L16.583 7 12 11.667zm0 0h11-11z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.583 16.333L12 11.667m0 0L16.583 7M12 11.667h11"
      ></path>
    </svg>
  );
}
