import { ArrowLeftV1, ArrowLeftV2, ArrowRightV1, ArrowRightV2 } from './ArrowIcons';

interface _IArrowIcon {
  onClick?: any;
  arrowDirection?: 'left' | 'right';
  arrowType?: 'v1' | 'v2';
  imageProps?: any;
}

export const ArrowIcon = (props: _IArrowIcon) => {
  const {
    onClick = () => {},
    imageProps = {
      height: 36,
      width: 36,
      style: {},
      className: '',
    },
    arrowDirection,
    arrowType,
  } = props;
  let Element = null;

  switch (arrowType) {
    case 'v1':
      Element = arrowDirection === 'left' ? ArrowLeftV1 : ArrowRightV1;
      break;
    case 'v2':
      Element = arrowDirection === 'left' ? ArrowLeftV2 : ArrowRightV2;
      break;
    default:
      Element = null;
  }

  if (!Element) return null;
  return (
    <div
      className="tw-cursor-pointer"
      style={{ height: imageProps.height, width: imageProps.width }}
      onClick={onClick}
    >
      <Element {...imageProps} />
    </div>
  );
};

export const ArrowCouple = ({
  handleNextClick = () => {},
  handlePrevClick = () => {},
  isVisible = false,
  arrowType = 'v1',
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <div className="tw-absolute tw-top-[50%] tw-flex tw-w-[100%] tw-translate-y-[-50%] tw-items-center tw-justify-between tw-px-[40px]">
      <ArrowIcon
        onClick={handlePrevClick}
        arrowDirection="left"
        arrowType={arrowType as any}
      />
      <ArrowIcon
        onClick={handleNextClick}
        arrowDirection="right"
        arrowType={arrowType as any}
      />
    </div>
  );
};
