import React, { useEffect, useRef } from 'react';
import styles from './ChevronType1.module.scss';
import { twMerge } from 'tailwind-merge';
import { ArrowCouple } from '../../../Arrows';

export function ChevronType1({
  showArrows,
  showChevrons,
  handlePrevClick,
  handleNextClick,
  chevronAnimationTiming,
  ...props
}) {
  const progressContainerRef = useRef<any>(null);
  const progressElementsRef = useRef<any>({});

  useEffect(() => {
    if (props.isAutoPlayOn) {
      const progress = Object.values(progressElementsRef.current) as any;

      const playNext = (e?: any) => {
        const current = e && e.target;
        let next;

        if (current) {
          const currentIndex = progress.indexOf(current);
          if (currentIndex < progress.length) {
            next = progress[currentIndex + 1];
          }
          current.classList.remove(styles['active']);
          current.classList.add(styles['passed']);
        }

        if (!next) {
          progress.map((el) => {
            el.classList.remove(styles['active']);
            el.classList.remove(styles['passed']);
          });
          next = progress[props.currentActiveSlideIndex || 0];
        }
        next?.classList?.add(styles['active']);
      };

      if (progress?.length) {
        progress.map((el) => el.addEventListener('animationend', playNext, false));
        playNext();
      }

      return () => {
        if (progress?.length) {
          progress.map((el) => el.removeEventListener('animationend', playNext, false));
        }
      };
    }
  }, [props.currentActiveSlideIndex, props.isAutoPlayOn]);

  return (
    <div
      className={twMerge(
        'chevronType1Container',
        'tw-relative tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-px-[40px] tw-py-[24px]'
      )}
    >
      {showArrows && (
        <ArrowCouple
          isVisible={showArrows}
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
        />
      )}
      {showChevrons && (
        <div
          className={twMerge(styles['progress-container'], 'tw-mt-auto tw-gap-[6px]')}
          ref={progressContainerRef}
        >
          {props.images.map((_, index) => {
            return (
              <div
                key={index}
                className={twMerge(
                  styles['progress'],
                  !props.isAutoPlayOn ? styles['isNotAnimatable'] : '',
                  !props.isAutoPlayOn &&
                    (props.currentActiveSlideIndex === index ? styles['isActive'] : '')
                )}
                style={{ animationDuration: chevronAnimationTiming }}
                ref={(node) => (progressElementsRef.current[index] = node)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
